/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import {
    IonHeader,
    IonBadge,
    IonToolbar,
    IonButton,
    IonIcon,
    IonButtons,
    IonBackButton, IonTitle, IonGrid, IonRow, IonCol
} from "@ionic/react";
import React, {useState,useEffect} from "react";
import {useHistory} from "react-router";
import {cart, logoAppleAppstore, logoGooglePlaystore, menuOutline, person, search} from "ionicons/icons";
import Cart from "../../lib/cart/Cart";
import {emmitEvent, EVENTS} from '../../lib/events/events';

//style
import "../../css/components/webHeader.css";
import '../../css/style.css';

//components
import GradientDivider from "./GradientDivider";
import BtnToolbarLogo from "./BtnToolbarLogo";
import SearchbarWeb from "../search/SearchbarWeb";
import SearchbarResults from "../search/SearchbarResults";
import NbioApi from "../../lib/api/NbioApi";
import { Link } from "react-router-dom";
import CartPopup from "../cart/CartPopup";
import ProfilePopup from "../profile/ProfilePopup";
import AppBannerHeader from "./AppBannerHeader";

interface webHeaderProps {
    title? :string
    customButton?: any,
    showSearchButton?: boolean
    showCartButton?: boolean
    showMenuButton?: boolean
    customEl?: any
}

const WebHeader: React.FC<webHeaderProps> = ({
                                                 title='',
                                                 customButton = null,
                                                 showSearchButton = true,
                                                 showCartButton = true,
                                                 showMenuButton = true,
                                                 customEl = null
}) => {
    const history = useHistory();
    const [popoverState, setShowPopover] = useState({
        showPopover: false,
        event: undefined,
    });
    const [cartItems, setCartItems] = useState(0);
    const [firstRender, setFirstRender] = useState(true);
    const [searchResults, setSearchResults] = useState({});
    const [searchStep, setSearchStep] = useState('');
    const [query,setQuery] = useState('');
    const [categories,setCategories] = useState([]);
    const [showCartPopover, setShowCartPopover] = useState(false);
    const [showProfilePopover, setShowProfilePopover] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const updateCartItem = (showIsLoading = false) => {
        if(showIsLoading){
            setIsLoading(true);
        }
        Cart.getCart().then((res) => {
            setCartItems(res.data.countItems);
        }).catch((ex) => {
            setCartItems(0);
        }).finally(() =>{
            if(showIsLoading){
                setIsLoading(false);
            }
        })
    }

    const getCategories = () =>{
        NbioApi.categories.get(1,5).then((res) =>{
            setCategories(res.data.categories);
        }).catch((ex) =>{

        });
    }

    useEffect(() => {
        if(firstRender){
            updateCartItem(false);
            setFirstRender(false);
            getCategories();
        }
    }, [cartItems]);
    useEffect(() =>{
        // @ts-ignore
        window.addEventListener(EVENTS.UPDATE_CART, updateCartItem);
        // @ts-ignore
        window.addEventListener(EVENTS.ADD_TO_CART, updateCartItem);
        return () => {
            // @ts-ignore
            window.removeEventListener(EVENTS.UPDATE_CART, updateCartItem);
            // @ts-ignore
            window.removeEventListener(EVENTS.ADD_TO_CART, updateCartItem);
        }
    },[]);

    const  renderBadge = () => {
        if(cartItems > 0){
            return <IonBadge className="web-header-badge" color={"danger"}>{cartItems}</IonBadge>
        }else{
            return null
        }
    }
    const openMenu = () =>{
        emmitEvent(EVENTS.OPEN_MENU,{});
    }
    const toggleCart = () =>{
        setShowCartPopover(!showCartPopover);
        setShowProfilePopover(false);
        setSearchStep('');
    }
    const toggleProfile = () =>{
        setShowCartPopover(false);
        setShowProfilePopover(!showProfilePopover);
        setSearchStep('');
    }
    const setSearchStepCloseOthers = (step:string) =>{
        setSearchStep(step);
        setShowProfilePopover(false);
        setShowCartPopover(false);
    }
    return (
        <>
            <AppBannerHeader></AppBannerHeader>
            <IonHeader className={"web-header"}>
                {/*Top Header*/}
                {
                    showSearchButton ?

                        <IonGrid className={'top-header ion-hide-lg-down'}>
                            <IonRow>
                                <IonCol size={'6'} className={'ion-no-padding border-right'}>
                                    <a className={'flex-row ion-justify-content-end ion-margin-end'}
                                       href={'https://apps.apple.com/us/app/nbio/id1605823846'}
                                       target={'_blank'}>
                                        <IonIcon className={'margin-right-half'}
                                                 icon={logoAppleAppstore}
                                                 size={'large'}>
                                        </IonIcon>
                                        <div className={'flex-col'}>
                                            <small>Descarga nuestra app</small><b>App Store</b>
                                        </div>
                                    </a>
                                </IonCol>
                                <IonCol size={'6'} className={'ion-no-padding'}>
                                    <a className={'flex-row ion-margin-start'}
                                       href={'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US'}
                                       target={'_blank'}>
                                        <IonIcon className={'margin-right-half'}
                                                 icon={logoGooglePlaystore}
                                                 size={'large'}>
                                        </IonIcon>
                                        <div className={'flex-col'}>
                                            <small>Descarga nuestra app</small>
                                            <b>Google Play</b>
                                        </div>
                                    </a>
                                </IonCol>
                            </IonRow>
                        </IonGrid> : null
                }

                {/*Toolbar*/}
                <IonToolbar className={"web-header-toolbar"}>
                    <IonButtons slot={"start"}>
                        <IonBackButton></IonBackButton>
                        {
                            title ?
                                <>
                                    <IonTitle className={'ion-hide-lg-up'}>
                                        {title}
                                    </IonTitle>
                                    <BtnToolbarLogo className={'ion-hide-lg-down'}/>
                                    <IonButton fill="clear" className="web-header-nbio ion-hide-lg-down" routerDirection="root" routerLink="/inicio"></IonButton>
                                </>

                                :
                                <>
                                    <BtnToolbarLogo/>
                                    <IonButton fill="clear" className="web-header-nbio" routerDirection="root" routerLink="/inicio"></IonButton>
                                </>
                        }
                    </IonButtons>

                    {/*Searchbar on web*/}
                    {
                        showSearchButton ?
                            <SearchbarWeb onSearchFinished={(r: any) => setSearchResults(r)}
                                          onUpdateSearchStep={(step: any) => setSearchStepCloseOthers(step)}
                                          onQueryChanged={(q: string) => setQuery(q)}
                            >
                            </SearchbarWeb> : null
                    }

                    {/*Right Buttons*/}
                    <IonButtons slot={"end"}>
                        {
                            showSearchButton ?
                                <IonButton  size="large"
                                            className={'ion-hide-lg-up'}
                                            fill={'clear'}
                                            routerDirection="forward" routerLink="/busqueda">
                                    <IonIcon slot="icon-only" icon={search}></IonIcon>
                                </IonButton>
                                :null
                        }

                        {
                            showCartButton ?
                                <>
                                    {/*Icon cart large devices (show cart popup)*/}
                                    <IonButton
                                        className={'ion-hide-lg-down icon-profile'}
                                        size="large"
                                        fill={'clear'}
                                        onClick={() => toggleProfile()}>
                                        <IonIcon slot="icon-only" icon={person}></IonIcon>
                                    </IonButton>
                                    <IonButton
                                        className={'ion-hide-lg-down icon-cart'}
                                        size="large"
                                        fill={'clear'}
                                        onClick={() => toggleCart()}>
                                        <IonIcon slot="icon-only" icon={cart}></IonIcon>
                                        {renderBadge()}
                                    </IonButton>

                                    {/*Icon cart mobile devices (navigate to cart)*/}
                                    <IonButton size="large"
                                               className={'ion-hide-lg-up'}
                                               fill={'clear'}
                                               routerLink={"/carrito"}
                                               routerDirection={"forward"}>
                                        <IonIcon slot="icon-only" icon={cart}></IonIcon>
                                        {renderBadge()}
                                    </IonButton>
                                </>
                                : null
                        }

                        {
                            customButton || null
                        }

                        {
                            showMenuButton?
                                <IonButton size="large"
                                           fill={'clear'}
                                           className={'ion-hide-lg-up'}
                                           onClick={() => openMenu()}
                                >
                                    <IonIcon slot="icon-only"icon={menuOutline}></IonIcon>
                                </IonButton>
                                : null
                        }
                    </IonButtons>
                </IonToolbar>
                {/*Links*/}
                {
                    showSearchButton ?
                        <div className={'ion-hide-lg-down navigation-bar flex-row ion-justify-content-around'}>

                            {
                                categories.map((category:any) =>{
                                    return (
                                        <Link  key={category._id}  to={`/categorias/${category.handleUrl}`} >
                                            {category.name}
                                        </Link>
                                    )
                                })
                            }
                            <Link  to={`/descuentos`}>
                                Descuentos
                            </Link>
                            <Link  to={`/categorias`}>
                                Ver Categorías
                            </Link>
                        </div> :null
                }

                <SearchbarResults
                    searchResults={searchResults}
                    searchStep={searchStep}
                    onDismiss={() => setSearchStepCloseOthers('')}
                    query={query}>
                </SearchbarResults>
                <CartPopup showPopup={showCartPopover} onClickClosePopup={() => setShowCartPopover(false)}/>
                <ProfilePopup showPopup={showProfilePopover} onClickClosePopup={() => setShowProfilePopover(false)}/>
                <GradientDivider/>
                {
                    customEl ? customEl : null
                }
            </IonHeader>
        </>

  )
}
export default WebHeader;
