/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */
import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonPage,
    withIonLifeCycle,
    IonContent,
    IonIcon,
    IonText
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {checkmarkCircleOutline} from "ionicons/icons";

//style
import "../../css/pages/orderResult.css";
import Cart from "../../lib/cart/Cart";
import {emmitEvent, EVENTS} from "../../lib/events/events";


//Interfaces
interface OrderResultState {
    orderId: string;
}
interface OrderSucceededItem{
    paymentMethod:string;
    orderId:string;
}
interface OrderResultProps extends RouteComponentProps <{id:string}>{

}



class OrderResult extends React.Component<OrderResultProps, OrderResultState> {
    private paymentElement: any;
    constructor(props: OrderResultProps) {
        super(props);
        this.state = {
            orderId: ''
        }
    }

    ionViewDidEnter() {

        //get payment type from previous page
        Cart.saveCartId('');
        emmitEvent(EVENTS.ADD_TO_CART,{});
    }

    render() {
        const orderId = this.props.match.params.id;
        let i = this.props.location.state as OrderSucceededItem;
        let paymentMethod = '';
        if(i){
            if(i.paymentMethod){
                paymentMethod = i.paymentMethod;
            }
        }

        const txt = ['card','saved_card'].includes(paymentMethod)  ? '¡Has completado tu compra!' : '¡Has completado tu orden!';
        return (
            <IonPage className={'order-result'}>
                <IonContent fullscreen>
                    <IonGrid className={'h-100'}>
                        <IonRow className={"h-100 ion-justify-content-center ion-align-items-center"}>
                            <IonCol size-md="6" className={"ion-text-center"}>
                                <IonText color={"light"}>
                                    <h1 className={'flex-row ion-justify-content-center'}>
                                        <div className={'congrats-gif mr-2'}></div>
                                        ¡Felicidades!
                                        <div className={'congrats-gif ml-2 flip'}></div>
                                    </h1>
                                </IonText>

                                <IonText color={"light"} className={'no-margin-bottom'}><h5>{txt}</h5></IonText>
                                <div className={'order-result-ion'}></div>
                                <IonText color={"light"}><h5 className={'no-margin-top'}>¡Gracias por confiar en nosotros!</h5></IonText>
                                <IonText color={"light"}><h5>Llegaremos lo antes posible</h5></IonText>
                                <IonButton color="light"
                                           size="small"
                                           className={'ion-margin-top'}
                                           onClick={() =>{
                                               window.location.replace(`/ordenes/${orderId}`);
                                           }}
                                           fill={"solid"}>Ver orden
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(OrderResult);
