/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */
import {IonGrid, IonRow, IonCol, IonCard, IonList, IonIcon, IonSearchbar, IonSpinner} from "@ionic/react";
import type {CartItemType} from "../../lib/data_types/dataTypes";

//components
import CartProduct from "./CartProduct";
import {cart} from "ionicons/icons";
import React, {useEffect} from "react";
import {EVENTS} from "../../lib/events/events";
import Cart from "../../lib/cart/Cart";

interface CartListProps {
    products: CartItemType[],
    onDelete: any,
    onIncreaseQty: any
    onDecreaseQty: any,
    onAddPrescription: any
    isLoading: boolean
}

const renderList = (products: CartItemType[], onDelete: any, onIncreaseQty: any, onDecreaseQty: any, onAddPrescription:any) => {
        return products.map(product => {
            return (
                <CartProduct
                    product={product} key={product._id}
                    onDelete={onDelete}
                    onIncreaseQty={onIncreaseQty}
                    onDecreaseQty={onDecreaseQty}
                    onAddPrescription={(product:any) => onAddPrescription(product)}
                >

                </CartProduct>
            )
        })
}

const CartList: React.FC<CartListProps> = ({products, onDelete, onIncreaseQty, onDecreaseQty,onAddPrescription,isLoading}) => {
    if(isLoading){
        return (
            <div className={'ion-padding ion-text-center'}>
                <IonSpinner style={{width:'200px',height:'200px'}} name={'dots'}></IonSpinner>
            </div>
        )
    }
    if(products.length === 0){
        return (
                <div className={'ion-padding ion-text-center'}>
                    Tu carrito está vacío.
                    <br/>
                    <br/>

                    <IonIcon icon={cart} color={'medium'} size={'large'} slot={'icon-only'}></IonIcon>
                </div>
        )
    }
    return (
        <IonGrid >
            <IonRow className={"ion-justify-content-center"}>
                <IonCol size-md="6">
                    <IonCard className={'ion-no-margin h-100'} >
                        <IonList>
                            {renderList(products.slice().reverse(), onDelete, onIncreaseQty, onDecreaseQty,onAddPrescription)}
                        </IonList>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default CartList;
