/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */
import {
    IonItem,
    IonThumbnail,
    IonLabel,
    IonNote,
    IonButton,
    IonIcon,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonChip,
    IonCard,
    IonText
} from "@ionic/react";
import type {CartItemType} from "../../lib/data_types/dataTypes";
import {addCircle, eye, removeCircle} from "ionicons/icons";
import {useHistory} from "react-router-dom";
import { money } from "../../lib/money/money";

//style
import "../../css/components/cartProduct.css";

//interface
interface CartProduct {
    product: CartItemType,
    onDelete: any,
    onIncreaseQty: any,
    onDecreaseQty: any,
    onAddPrescription: any
}

const CartProduct: React.FC<CartProduct> = ({product, onDelete, onIncreaseQty, onDecreaseQty, onAddPrescription}) => {
    const history = useHistory();

    const _onIncreaseQty = (product: CartItemType) => {
        onIncreaseQty(product);
    }

    const _onDecreaseQty = (product: CartItemType) => {
        if(product.quantity === 1){
            onDelete(product);
        }else{
            onDecreaseQty(product);
        }
    }

    const onRemoveItem = (product: CartItemType) => {
        onDelete(product);
    }

    const goToProduct = (productId: string) => {
        history.push(`/productos/${productId}`);
    }

    const renderControlledCard = (product: CartItemType) => {
        if (product.isControlled) {
            if(!product.prescription){
                return (
                    <IonCard color="danger" button onClick={() => {
                        onAddPrescription(product);
                    }}>
                        <IonLabel className="ion-text-wrap padding-half">
                            ¡Este medicamento es controlado!<br></br> Toca aquí para agregar la receta médica.
                        </IonLabel>
                    </IonCard>
                )
            }

        }
    }


    const renderPrescription = (product: CartItemType) => {
        if (product.prescription) {
            return (
                <IonChip color='success' onClick={() =>{
                    onAddPrescription(product);
                }}>
                    <IonIcon icon={eye} ></IonIcon>
                    <IonLabel className='ion-text-wrap padding-half'>
                         Ver receta agregada
                    </IonLabel>
                </IonChip>
            )
        }else{
            return null;
        }
    }

    const renderPrice = () => {
        return(
            <IonLabel color="danger">
                {
                    product.discount ?
                        <IonText color={'medium'} className={'line-through mr-1'}>
                            <small>{money(product.originalPrice)}</small>
                        </IonText>
                     : null
                }
                <b>{money(product.price)}</b>
            </IonLabel>
        )
    }

    return (
        <IonItemSliding>
            <IonItem lines={'full'}>
                <IonThumbnail slot="start" onClick={() => goToProduct(product.SKU)} className="cursor-pointer">
                    <img src={product.imageUrl}/>
                </IonThumbnail>
                <div className="padding-vertical-half">
                    <IonNote className="ion-text-wrap cursor-pointer"
                             /*Description in the cart is the name of the product*/
                             onClick={() => goToProduct(product.SKU)}>{product.description}</IonNote>
                    {renderPrice()}
                    <div className={"cart-product-wrapper-btns ion-margin-top"}>
                        <IonButton className="ion-no-padding"
                                   size="small"
                                   color="dark"
                                   fill="clear"
                                   onClick={() => _onDecreaseQty(product)}>
                            <IonIcon slot="icon-only" icon={removeCircle}></IonIcon>
                        </IonButton>
                        <IonLabel className="cart-product-label-price">{product.quantity}</IonLabel>
                        <IonButton className="ion-no-padding"
                                   size="small"
                                   color="dark"
                                   fill="clear"
                                   onClick={() => _onIncreaseQty(product)}>
                            <IonIcon slot="icon-only" icon={addCircle}></IonIcon>
                        </IonButton>
                    </div>
                    {renderPrescription(product)}
                    {renderControlledCard(product)}
                </div>
            </IonItem>
            <IonItemOptions>
                <IonItemOption color="danger" onClick={() => onRemoveItem(product)}>
                    Eliminar
                </IonItemOption>
            </IonItemOptions>
        </IonItemSliding>

    );
};

export default CartProduct;
