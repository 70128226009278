/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */
import React from 'react';
import {
    IonContent,
    IonPage,
    IonToolbar,
    withIonLifeCycle,
    IonButtons,
    IonButton,
    IonIcon,
    IonCard,
    IonCardSubtitle,
    IonGrid,
    IonRow,
    IonCol,
    IonCardHeader,
    IonCardContent,
    IonFooter,
    IonLabel,
    IonToast, IonNote, isPlatform, IonAlert, IonText, IonChip
} from '@ionic/react';
import { RouteComponentProps} from "react-router";
import {
    addCircle, cardOutline, cubeOutline, home, locateOutline, logoWhatsapp,
    refreshOutline,
    removeCircle, starOutline,
} from "ionicons/icons";
import NbioApi from "../../lib/api/NbioApi";
import Cart from "../../lib/cart/Cart";
import {BrandType, ETAType, ProductType} from "../../lib/data_types/dataTypes";
// Share
import { Share } from '@capacitor/share';
// DOM Purifier
import DOMPurify from 'dompurify';

//style
import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';
import '../../css/pages/productDetails.css';

//components
import Product from "../../components/products/Product";
import RecommendedProducts from "../../components/products/RecommendedProducts";
import WebHeader from "../../components/ui/WebHeader";
import WebFooter from "../../components/ui/WebFooter";
import BuyItNowButton from "../../components/products/BuyItNowButton";
import {Link} from "react-router-dom";
import SellingPoints from "../../components/products/SellingPoints";

// app utils
const {setAppTitle} = require('../../lib/app-utils/app-utils');



interface ProductDetailsProps
    extends RouteComponentProps<{
        id: string;
    }> {
    onRecommendedClick:any;
};

interface ProductDetailsState{
    product: ProductType;
    quantity:number;
    recommendedProducts: ProductType[];
    showToast: boolean;
    toastMsg: string;
    productNotFound:boolean;
    productErrored:boolean;
    slidesPerView:number;
    isAskDownloadAppAlertOpen:boolean;
    brand: BrandType | any;
    eta: ETAType | any;
    sanitizedRichDescription: string;
    headerHeight: number;
}

class ProductDetails extends React.Component<ProductDetailsProps,ProductDetailsState>{
    private qty: number;
    private contentRef: any;
    constructor(props: ProductDetailsProps) {
        super(props);
        this.state = {
            product: {
                _id: '',
                name:'',
                images:[''],
                price: 0,
                sku:'',
                description:'',
                handleUrl:'',
                sellingPoints: []
            },
            quantity:1,
            recommendedProducts:[],
            showToast:false,
            toastMsg: '',
            productNotFound:false,
            productErrored:false,
            slidesPerView:2,
            isAskDownloadAppAlertOpen:false,
            brand:{},
            eta:{},
            sanitizedRichDescription:'',
            headerHeight:0
        }
        // avoids quantity sliding issue //TODO NOT WORKING
        this.qty = 1;
        this.onRecommendedClick = this.onRecommendedClick.bind(this);
        this.onResize = this.onResize.bind(this);

    }
    goToStore = () =>{
        const isIos = isPlatform('ios');
        window.open(isIos ? 'https://apps.apple.com/us/app/nbio/id1605823846' : 'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US');
    }
    async clearProduct(){
        this.qty = 1;
        return new Promise((resolve => {
            this.setState({
                product:{
                    _id:'',
                    name:'',
                    images:[''],
                    price:0,
                    sku:'',
                    description:''
                },
                eta:{},
                brand: {},
                quantity:1,
                recommendedProducts:[],
                showToast:false
            }, () =>{
                resolve(true);
            })
        }));
    }
    ionViewDidEnter(){
        this.loadProduct();
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }
    ionViewDidLeave(){
        window.removeEventListener('resize', this.onResize);
    }
    onResize(){
        const width  = window.innerWidth;
        let slidesPerView = 0;
        if(width <= 576){
            slidesPerView = 2;
        }else if(width <= 768){
            slidesPerView = 3;
        }else if(width <= 992){
            slidesPerView = 4;
        }else{
            slidesPerView = 6;
        }
        this.setState({slidesPerView:slidesPerView});
    }
    async loadProduct(){
        this.setState({
            productErrored:false,
            productNotFound:false
        })
        let sku = this.props.match.params.id;
        // Clear product
        await this.clearProduct();
        NbioApi.products.getProduct(sku).then((res) =>{
            let newProduct = res.data.product as ProductType;
            let brand = res.data.brand ? res.data.brand : undefined;
            const richDescription = (newProduct.richDescription) || '';
            this.setState({"product": newProduct});
            this.setState({"brand": brand});
            this.setState({'eta': res.data.eta});
            this.setState({sanitizedRichDescription: DOMPurify.sanitize(richDescription)})
            // setAppTitle
            const title = `Farmacia Nbio | ${newProduct.name} | Envíos a todo México`;
            setAppTitle(title)
            //facebook tracking
            try{
                // @ts-ignore
                fbq('track', 'ViewContent');
            }catch(ex){

            }
            //google tracking
            try{
                // @ts-ignore
                gtag("event", "view_item", {
                    currency: "MXN",
                    value: newProduct.price,
                    items: [
                        {
                            item_id: newProduct._id,
                            item_name: newProduct.name,
                            item_brand: newProduct.brandName || '',
                            item_category: newProduct.categories ? newProduct.categories[0] : '',
                            price: newProduct.price,
                            quantity: 1
                        }
                    ]
                });
            }catch(ex){

            }
        }).catch((ex) =>{
            const errorMsg = ex.response?.data?.error_es;
            const errorCode = ex.response?.data?.error_code;
            if(errorCode === 'PRODUCTS_404'){
                // show error
                this.setState({productNotFound:true})
            }else{
                this.setState({productErrored:true})
            }

        })

        //TODO:GET THE RECOMMENDED PRODUCTS
        NbioApi.products.getRecommended(' ').then(res => {
            this.setState({recommendedProducts:res.data.products});
        }).catch((ex) =>{
        })
    }

    addToCart = () =>{
        const search = this.props.location.search;
        let adId = search.replace('?AdId=','');
        Cart.addProduct(this.state.product._id,this.state.quantity,true,adId).then((res) =>{
               //set toast
            this.setState({
                showToast: true,
                toastMsg: 'Producto agregado'
            })
            try{
                // @ts-ignore
                fbq('track', 'AddToCart');
            }catch(ex){

            }
            //google tracking
            const p = this.state.product;
            try{
                // @ts-ignore
                gtag("event", "add_to_cart", {
                    currency: "MXN",
                    value: p.price,
                    items: [
                        {
                            item_id: p._id,
                            item_name: p.name,
                            item_brand: p.brandName || '',
                            item_category: p.categories ? p.categories[0] : '',
                            price: p.price,
                            quantity: this.state.quantity
                        }
                    ]
                });
            }catch(ex){

            }
    
        }).catch((ex) => {
            if (ex === 'Unsupported web view') {
                this.setState({isAskDownloadAppAlertOpen:true});
            }
        });

    }

    increaseQty =  () => {
        let newQty = this.qty + 1 >= 20 ? 20 : this.qty + 1;
        this.qty = newQty;
        this.setState( {
                quantity: newQty,
        });
    }

    decreaseQty = () =>{
        let newQty = this.qty - 1 <= 1 ? 1 : this.qty - 1;
        this.qty = newQty;

        this.setState( {
            quantity: newQty
        });
    }

    share = async (product: ProductType) =>{
        try{
            await Share.share({
                title: 'Farmacia Nbio',
                text: `Farmacia Nbio | ${product.name}`,
                url: `${process.env.REACT_APP_APP_URL}/productos/${product.handleUrl}`,
                dialogTitle: 'Comparte este producto de Nbio.',
            });
        }catch (e) {
        }
    }

    renderProductNotFound(){
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }} >
                <IonNote>Producto no encontrado</IonNote>
                <IonButton fill={'outline'} size={'small'} routerLink={'/inicio'}>
                    <IonIcon icon={home}></IonIcon>
                    &nbsp;Volver al inicio
                </IonButton>
            </div>
        )
    }

    renderProductErrored(){
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }} onClick={() => {
                this.loadProduct();
            }}>
                <IonNote>¡Algo salió mal!</IonNote>
                <IonButton fill={'outline'} size={'small'}>
                    <IonIcon icon={refreshOutline}></IonIcon>
                    Reintentar
                </IonButton>
            </div>
        )
    }

    renderProduct = (adId : string) => {
        const product = this.state.product;
        return(
            <>
                <div className={'ion-hide-lg-down'}>
                    <Product product={product}
                             adId={adId}
                             hideInfo={true}
                    />
                </div>
                <div className={'ion-hide-lg-up'}>
                    <div className={'wrapper-product'}>
                        <Product product={product}
                                 isMobileView={true}
                                 adId={adId}
                                 eta={this.state.eta}
                        />
                    </div>
                </div>
            </>
        )
    }

    renderStars = () => {
        return (
            <div className={'flex-row'}>
                <IonIcon icon={starOutline} color={'medium'}>
                </IonIcon>
                <IonIcon icon={starOutline} color={'medium'}>
                </IonIcon>
                <IonIcon icon={starOutline} color={'medium'}>
                </IonIcon>
                <IonIcon icon={starOutline} color={'medium'}>
                </IonIcon>
                <IonIcon icon={starOutline} color={'medium'}>
                </IonIcon>
            </div>
        )
    }

    renderCategoryLabel = (product: any) => {
        const category = product.categories ? product.categories[0] : '';
        const listName = product?.list?.name;
        return (
            <IonChip color={'danger'}
                     className={'ion-justify-content-start no-margin-left ion-text-uppercase'}>
                {listName || category}
            </IonChip>
        );
    }

    goToCart(){
        this.props.history.push('/carrito');
    }
    renderStickyHeader(){
        const brand = this.state.brand;
        const product = this.state.product;
        return (
            <div className={'header-product-details-mobile ion-hide-lg-up'}>
                <div className={'hpdm-title'}>
                    <Link className={'hpdm-link ion-text-capitalize'} to={`/marcas/${brand?.handleUrl}`}>
                        {brand?.name ? `${brand?.name} | ` : null} {product.name}
                    </Link>
                </div>
                <div className={'hpdm-content'}>
                    {this.renderCategoryLabel(product)}
                    {this.renderStars()}
                </div>
            </div>
        )
    }
    render(){
        const search = this.props.location.search;
        let adId = search.replace('?AdId=','');
        const product = this.state.product;
        const image = (Array.isArray(product?.images) ? product.images[0] : '') || '';
        const isIos = isPlatform('ios');
        return (
            <IonPage>
                <WebHeader customEl={this.renderStickyHeader()}></WebHeader>
                <IonContent className=""  ref={(ref) => this.contentRef = ref} scrollEvents={true}>
                    <IonToast         isOpen={this.state.showToast}
                                      onDidDismiss={() => this.setState({showToast: false})}
                                      buttons={[
                                          {
                                              text: 'Ir al carrito',
                                              role: 'info',
                                              handler: () => {
                                                  this.goToCart();
                                              }
                                          }
                                      ]}
                                      message={this.state.toastMsg}
                                      duration={5000} />
                    <IonGrid>
                        {/*Product component & add to cart*/}
                        <IonRow className={"ion-justify-content-center"}>
                            <IonCol size-lg={5} size-md={12} itemType="https://schema.org/Product" itemScope>
                                {/* start meta data*/}
                                <meta itemProp="name" content={this.state.product.name}/>
                                <link itemProp="image" href={image}/>
                                <meta itemProp="description" content={product.description}/>
                                <link itemProp="url" href={`${process.env.REACT_APP_APP_URL}/productos/${product.handleUrl}`}/>
                                <meta itemProp="sku" content={product.sku}/>
                                <meta itemProp="gtin13" content={product.upc}/>
                                <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
                                    <meta itemProp="name" content={product.brandName}/>
                                </div>
                                <div itemProp="offers" itemType="https://schema.org/Offer" itemScope>
                                    <meta itemProp="itemCondition" content="https://schema.org/NewCondition"/>
                                    <meta itemProp="availability" content="https://schema.org/InStock"/>
                                    <meta itemProp="price" content={product.price.toString()}/>
                                    <meta itemProp="priceCurrency" content="MXN"/>
                                </div>
                                {/*end meta data*/}

                                {
                                    this.state.productNotFound ? this.renderProductNotFound() : null
                                }
                                {
                                    this.state.productErrored ? this.renderProductErrored() : null
                                }
                                {
                                    this.state.productNotFound || this.state.productErrored ? null : this.renderProduct(adId)
                                }

                                {/*Selling Points*/}
                                <SellingPoints sellingPoints={product?.sellingPoints} classNameCard={'ion-hide-lg-up'}/>

                                {/*Description*/}
                                {
                                    this.state.sanitizedRichDescription ?
                                        <div className="ion-padding">
                                            <div dangerouslySetInnerHTML={
                                                {__html:this.state.sanitizedRichDescription}
                                            }>
                                            </div>
                                        </div>
                                        :
                                        <IonCardContent className="ion-text-justify ion-text-wrap ion-margin-top">
                                            {this.state.product.description}
                                        </IonCardContent>
                                }
                                {/*//Todo: Remove*/}
                                {/*<IonCard className={"no-margin-horizontal ion-hide-lg-up"}>*/}
                                {/*    <IonCardContent>*/}
                                {/*        /!*Selling Points 1*!/*/}
                                {/*        <div className={'flex-row ion-margin-bottom'}>*/}
                                {/*            <IonIcon className={'ion-padding-end'} icon={locateOutline} size={'large'}></IonIcon>*/}
                                {/*            <div>*/}
                                {/*                <IonText className={'product-detail-label-icons'}><b>Seguimiento en tiempo real</b></IonText>*/}
                                {/*                <ul className={'ion-padding-start'}>*/}
                                {/*                    <li>*/}
                                {/*                        Seguimiento de posición de GPS. (Solo CDMX)*/}
                                {/*                    </li>*/}
                                {/*                    <li className={''}>*/}
                                {/*                        Seguimiento de temperatura. (Solo productos que requieren refrigeración)*/}
                                {/*                    </li>*/}
                                {/*                    <li>*/}
                                {/*                        Seguimiento de humedad. (Solo productos que requieren refrigeración)*/}
                                {/*                    </li>*/}
                                {/*                </ul>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}

                                {/*        /!*Selling Points 2*!/*/}
                                {/*        <div className={'flex-row ion-margin-bottom'}>*/}
                                {/*            <IonIcon className={'ion-padding-end'} icon={cardOutline} size={'large'}></IonIcon>*/}
                                {/*            <div>*/}
                                {/*                <IonText className={'product-detail-label-icons'}><b>Métodos de pago</b></IonText>*/}
                                {/*                <ul className={'ion-padding-start'}>*/}
                                {/*                    <li>*/}
                                {/*                        Pagos en contra entrega en efectivo y con terminal punto de venta. (Solo CDMX)*/}
                                {/*                    </li>*/}
                                {/*                    <li className={''}>*/}
                                {/*                        Tarjetas de Crédito y Débito.*/}
                                {/*                    </li>*/}
                                {/*                    <li>*/}
                                {/*                        Pagos seguros con MercadoPago.*/}
                                {/*                    </li>*/}
                                {/*                </ul>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        /!*Selling Points 3*!/*/}
                                {/*        <div className={'flex-row ion-margin-bottom'}>*/}
                                {/*            <IonIcon className={'ion-padding-end'} icon={cubeOutline} size={'large'}></IonIcon>*/}
                                {/*            <div>*/}
                                {/*                <IonText className={'product-detail-label-icons'}><b>Envíos</b></IonText>*/}
                                {/*                <ul className={' ion-padding-start'}>*/}
                                {/*                    <li>*/}
                                {/*                        Envíos en el mismo día en CDMX.*/}
                                {/*                    </li>*/}
                                {/*                    <li>*/}
                                {/*                        Envíos con entrega programada en CDMX.*/}
                                {/*                    </li>*/}
                                {/*                    <li>*/}
                                {/*                        Envíos a toda la república Mexicana.*/}
                                {/*                    </li>*/}
                                {/*                </ul>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}

                                {/*        /!*Selling Points 4*!/*/}
                                {/*        <div className={'flex-row'}>*/}
                                {/*            <IonIcon className={'ion-padding-end'} icon={logoWhatsapp} size={'large'}></IonIcon>*/}
                                {/*            <div>*/}
                                {/*                <IonText className={'product-detail-label-icons'}><b>Atención a clientes</b></IonText>*/}
                                {/*                <ul className={'ion-padding-start'}>*/}
                                {/*                    <li>*/}
                                {/*                        Soporte por Teléfono al &nbsp;*/}
                                {/*                        <a href={'tel:+525649357151'} target={'_blank'}>*/}
                                {/*                            (564) 935-7151*/}
                                {/*                        </a>*/}
                                {/*                    </li>*/}
                                {/*                </ul>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}

                                {/*    </IonCardContent>*/}
                                {/*</IonCard>*/}
                            </IonCol>
                            {/*shown on web view*/}
                            {
                                this.state.productNotFound || this.state.productErrored ? null :
                                    <IonCol size-lg={5} className={"ion-hide-lg-down"}>
                                        <Product product={this.state.product}
                                                 brand={this.state.brand}
                                                 eta={this.state.eta}
                                                 isWeb={true}
                                                 adId={adId}
                                                 hideImage={true}/>

                                        {/*Selling Points*/}
                                        <SellingPoints sellingPoints={product?.sellingPoints}/>

                                        {/*//Todo: Remove*/}
                                        {/*<IonCard className={"no-margin-horizontal"}>*/}
                                        {/*    <IonCardContent>*/}
                                        {/*        /!*Selling Points 1*!/*/}
                                        {/*        <div className={'flex-row ion-margin-bottom'}>*/}
                                        {/*            <IonIcon className={'ion-padding-end'} icon={locateOutline} size={'large'}></IonIcon>*/}
                                        {/*            <div>*/}
                                        {/*                <IonText className={'product-detail-label-icons'}><b>Seguimiento en tiempo real</b></IonText>*/}
                                        {/*                <ul className={'ion-padding-start'}>*/}
                                        {/*                    <li>*/}
                                        {/*                        Seguimiento de posición de GPS. (Solo CDMX)*/}
                                        {/*                    </li>*/}
                                        {/*                    <li className={''}>*/}
                                        {/*                        Seguimiento de temperatura. (Solo productos que requieren refrigeración)*/}
                                        {/*                    </li>*/}
                                        {/*                    <li>*/}
                                        {/*                        Seguimiento de humedad. (Solo productos que requieren refrigeración)*/}
                                        {/*                    </li>*/}
                                        {/*                </ul>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}

                                        {/*        /!*Selling Points 2*!/*/}
                                        {/*        <div className={'flex-row ion-margin-bottom'}>*/}
                                        {/*            <IonIcon className={'ion-padding-end'} icon={cardOutline} size={'large'}></IonIcon>*/}
                                        {/*            <div>*/}
                                        {/*                <IonText className={'product-detail-label-icons'}><b>Métodos de pago</b></IonText>*/}
                                        {/*                <ul className={'ion-padding-start'}>*/}
                                        {/*                    <li>*/}
                                        {/*                        Pagos en contra entrega en efectivo y con terminal punto de venta. (Solo CDMX)*/}
                                        {/*                    </li>*/}
                                        {/*                    <li className={''}>*/}
                                        {/*                        Tarjetas de Crédito y Débito.*/}
                                        {/*                    </li>*/}
                                        {/*                    <li>*/}
                                        {/*                        Pagos seguros con MercadoPago.*/}
                                        {/*                    </li>*/}
                                        {/*                </ul>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*        /!*Selling Points 3*!/*/}
                                        {/*        <div className={'flex-row ion-margin-bottom'}>*/}
                                        {/*            <IonIcon className={'ion-padding-end'} icon={cubeOutline} size={'large'}></IonIcon>*/}
                                        {/*            <div>*/}
                                        {/*                <IonText className={'product-detail-label-icons'}><b>Envíos</b></IonText>*/}
                                        {/*                <ul className={' ion-padding-start'}>*/}
                                        {/*                    <li>*/}
                                        {/*                        Envíos en el mismo día en CDMX.*/}
                                        {/*                    </li>*/}
                                        {/*                    <li>*/}
                                        {/*                        Envíos con entrega programada en CDMX.*/}
                                        {/*                    </li>*/}
                                        {/*                    <li>*/}
                                        {/*                        Envíos a toda la república Mexicana.*/}
                                        {/*                    </li>*/}
                                        {/*                </ul>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}

                                        {/*        /!*Selling Points 4*!/*/}
                                        {/*        <div className={'flex-row'}>*/}
                                        {/*            <IonIcon className={'ion-padding-end'} icon={logoWhatsapp} size={'large'}></IonIcon>*/}
                                        {/*            <div>*/}
                                        {/*                <IonText className={'product-detail-label-icons'}><b>Atención a clientes</b></IonText>*/}
                                        {/*                <ul className={'ion-padding-start'}>*/}
                                        {/*                    <li>*/}
                                        {/*                        Soporte por Teléfono al &nbsp;*/}
                                        {/*                        <a href={'tel:+525649357151'} target={'_blank'}>*/}
                                        {/*                            (564) 935-7151*/}
                                        {/*                        </a>*/}
                                        {/*                    </li>*/}
                                        {/*                </ul>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </IonCardContent>*/}
                                        {/*</IonCard>*/}
                                    </IonCol>
                            }
                        </IonRow>

                        {/*Products Recommended*/}
                        <IonRow className={"ion-justify-content-center"}>
                            <IonCol size={'12'}>
                                <IonCard className={"no-margin-horizontal"}>
                                    <IonCardHeader color="dark">
                                        <IonCardSubtitle className={'ion-text-capitalize'}>
                                            Quienes Compraron este producto También Llevan:
                                        </IonCardSubtitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <RecommendedProducts products={this.state.recommendedProducts}
                                                             slidesPerView={this.state.slidesPerView}
                                                             onClick={(p:any) => this.onRecommendedClick(p)}>
                                        </RecommendedProducts>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <WebFooter></WebFooter>
                </IonContent>
                <IonFooter className={'ion-hide-lg-up'}>
                    <IonToolbar className={'ion-no-padding'}>
                        <IonGrid className={'ion-no-padding'}>
                            <IonRow className={'ion-justify-content-center ion-no-padding'}>
                                <IonCol size-md={6} className={"flex-row"}>
                                    <IonToolbar className={''}>
                                        <BuyItNowButton product={this.state.product}
                                                        classBtn={'ion-margin-start ion-margin-end'}
                                                        adId={adId}/>
                                    </IonToolbar>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
                <IonAlert isOpen={this.state.isAskDownloadAppAlertOpen}
                          header={'Descarga nuestra app'}
                          message={'Para una mejor experiencia, descarga nuestra app'}
                          onDidDismiss={() => this.setState({isAskDownloadAppAlertOpen:false})}
                          buttons={[
                              {
                                  text: 'Cancelar',
                                  handler: () => {
                                      this.setState({isAskDownloadAppAlertOpen:false})
                                  },
                              },
                              {
                                  text: isIos ? 'Ir a la App Store' : 'Ir a Google Play',
                                  handler: () => {
                                      this.setState({isAskDownloadAppAlertOpen:false})
                                  },
                              },
                          ]}/>
            </IonPage>
        )
    }

    private onRecommendedClick(p: any) {
        this.props.onRecommendedClick(p);
        this.contentRef.scrollToTop();
    }
}

export default withIonLifeCycle(ProductDetails);
