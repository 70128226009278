/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import NbioApi from "../api/NbioApi";
import {isPlatform} from "@ionic/react";
const {emmitEvent,EVENTS} = require('../../lib/events/events');
const isUnsupportedWebView = () =>{
    const userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.includes('fb_iab') || userAgent.includes('fb4a') || userAgent.includes('fbav') || userAgent.includes('instagram')){
        return true;
    }else{
        return false;
    }
}
const Cart = {
    saveRedirectAfterLoginSuggestion:  () => {
        localStorage.setItem('nbio_redirect_to_confirm_suggestion','true');
    },
    deleteRedirectAfterLoginSuggestion: () => {
        localStorage.removeItem('nbio_redirect_to_confirm_suggestion');
    },
    hasRedirectAfterLoginSuggestion: () =>{
        const suggestion = localStorage.getItem('nbio_redirect_to_confirm_suggestion');
        return suggestion === 'true';
    },
    getCart: async () =>{
        const cartId = Cart.getCartId();
        if(cartId){
            return NbioApi.cart.getCart(cartId);
        }else{
            return Promise.reject(null)
        }
    },
    saveCartId: (cartId) =>{
        localStorage.setItem('nbio_cart_id',cartId);
    },
    forgetCart: () =>{
        localStorage.removeItem('nbio_cart_id');
    },
    getCartId: () =>{
        return localStorage.getItem('nbio_cart_id');
    },
    addProduct: async(productId,amount = 1,emmit =true,adId='') => {
        // Check if it's running on unsupported web view
        if(isUnsupportedWebView()){
            return Promise.reject('Unsupported web view');
        }
        const cartId = Cart.getCartId();
        return NbioApi.cart.addProduct(productId,amount,cartId,adId).then((r) =>{
            const cartId = r.data.cartId;
            Cart.saveCartId(cartId);
            if(emmit){
                emmitEvent(EVENTS.ADD_TO_CART,{});
            }
            // Emmit event
        }).catch((err) =>{
            console.log(err)
        })
    },
    emmitEvent: () =>{
        emmitEvent(EVENTS.ADD_TO_CART,{});
    },
    updateProduct: async (cartItemId,quantity,prescriptionId = null) =>{
        const cartId = Cart.getCartId();
        return NbioApi.cart.updateProduct(cartId,cartItemId,quantity,prescriptionId).then((r) =>{
            emmitEvent(EVENTS.UPDATE_CART,{});
            return Promise.resolve(r.data)
        })
    },
    removeProduct: async (cartItemId) =>{
        const cartId = Cart.getCartId();
        return NbioApi.cart.removeProduct(cartId,cartItemId).then((r) =>{
            emmitEvent(EVENTS.UPDATE_CART,{});
            return Promise.resolve(r.data)
        })
    },
    addCoupon: async (couponId) =>{
        const cartId = Cart.getCartId();
        return NbioApi.cart.addCoupon(cartId,couponId).then((r) =>{
            return Promise.resolve(r.data)
        })
    },
    removeCoupon: async (couponId) =>{
        const cartId = Cart.getCartId();
        return NbioApi.cart.removeCoupon(cartId,couponId).then((r) =>{
            return Promise.resolve(r.data)
        })
    },
}
export default Cart;
