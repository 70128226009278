/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

module.exports ={
    EVENTS: {
        'ADD_TO_CART':'ADD_TO_CART',
        'UPDATE_CART':'UPDATE_CART',
        'RELOAD_DEFAULT_CARD':'RELOAD_DEFAULT_CARD',
        'OPEN_MENU':'OPEN_MENU'
    },
    emmitEvent: (type,data) =>{
        const event = new CustomEvent(type, {
            data: data
        });
        window.dispatchEvent(event);
    }
}
